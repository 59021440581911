/**
 * @file Transform string into different case styles
 */
export function sanitizeAndCamelCaseTitle(title: string): string {
  // Use a regular expression to remove all non-alphanumeric characters and convert to camel case
  return title.replace(/[^a-zA-Z0-9]+([a-zA-Z0-9]?)/g, (_, capturedChar) => {
    return capturedChar ? capturedChar.toUpperCase() : ''
  })
}

export function snakeToKebabCase(snakeStr: string): string {
  if (snakeStr === null) {
    return ''
  }
  return snakeStr.replace(/_+/g, '-')
}

export function snakeToPascalCase(snakeStr: string): string {
  if (snakeStr === null) {
    return ''
  }
  return snakeStr
    .replace(/^(.)/, (_, firstChar) => firstChar.toUpperCase())
    .replace(/_+(.)/g, (_, capturedChar) => capturedChar.toUpperCase())
}
